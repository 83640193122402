<template>
    <section v-if="loading || ishihara">
        <div class="title">Teste de Ishihara</div>
        <!-- <div v-if="!loading"  class="time">Realizado em {{ parseDate(ishihara?.updatedAt) }}</div>
        <div v-else class="skeleton"><b-skeleton animation="fade" width="100%"></b-skeleton></div> -->

        <div class="subtitle">Resultados</div>
        <div v-if="!loading">Diagnóstico: <b>{{ ishihara?.diagnosysLabel  }}</b></div>
        <div v-else class="skeleton"><b-skeleton animation="fade" width="200px"></b-skeleton></div>
        
        <div v-if="!loading">Acertos: <b>{{ ishihara?.hits  }}/12</b></div>
        <div v-else class="skeleton"><b-skeleton animation="fade" width="200px"></b-skeleton></div>

        <!-- <div v-if="!loading">Respondidas: <b>{{ ishihara?.hitsList.join(', ')  }}</b></div>
        <div v-else class="skeleton"><b-skeleton animation="fade" width="200px"></b-skeleton></div>

        <div v-if="!loading">Não respondidas: <b>{{ ishihara?.missList.join(', ')  }}</b></div>
        <div v-else class="skeleton"><b-skeleton animation="fade" width="200px"></b-skeleton></div> -->
        
        <div class="subtitle">Respostas por tela:</div>
        <div v-if="!loading && ishihara?.answers" class="screens-wrapper">
            <div class="screen" :class="{ error: hasError(key, ishihara?.answers[key]) }" v-for="key in Object.keys(ishihara?.answers).filter(key => !['1', '14'].includes(key.replace(/^\D+/g, '')))" :key="key">
                <div class="screen-number">Tela {{  key.replace(/^\D+/g, '') }} <span class="rigth-response">({{ answers[key] }})</span></div>
                <div class="response">R: {{ ishihara?.answers[key] }}</div>
            </div>
        </div>
        <div v-else class="screens-wrapper">
            <div class="skeleton"><b-skeleton animation="fade" width="64px" height="64px"></b-skeleton></div>
            <div class="skeleton"><b-skeleton animation="fade" width="64px" height="64px"></b-skeleton></div>
            <div class="skeleton"><b-skeleton animation="fade" width="64px" height="64px"></b-skeleton></div>
            <div class="skeleton"><b-skeleton animation="fade" width="64px" height="64px"></b-skeleton></div>
            <div class="skeleton"><b-skeleton animation="fade" width="64px" height="64px"></b-skeleton></div>
            <div class="skeleton"><b-skeleton animation="fade" width="64px" height="64px"></b-skeleton></div>
            <div class="skeleton"><b-skeleton animation="fade" width="64px" height="64px"></b-skeleton></div>
            <div class="skeleton"><b-skeleton animation="fade" width="64px" height="64px"></b-skeleton></div>
            <div class="skeleton"><b-skeleton animation="fade" width="64px" height="64px"></b-skeleton></div>
            <div class="skeleton"><b-skeleton animation="fade" width="64px" height="64px"></b-skeleton></div>
            <div class="skeleton"><b-skeleton animation="fade" width="64px" height="64px"></b-skeleton></div>
            <div class="skeleton"><b-skeleton animation="fade" width="64px" height="64px"></b-skeleton></div>
            <div class="skeleton"><b-skeleton animation="fade" width="64px" height="64px"></b-skeleton></div>
            <div class="skeleton"><b-skeleton animation="fade" width="64px" height="64px"></b-skeleton></div>
        </div>
        <div v-if="!loading && ishihara?.answers" class="screen-number">* As respostas das telas 1 e 14 foram ignoradas durante o exame</div>
    </section>
</template>
<script>
    export default {
        props: {
            loading: Boolean,
            ishihara: Object
        },
        data() {
            return {
                answers: {
                    'screen1': '12',
                    'screen2': '8',
                    'screen3': '5',
                    'screen4': '29',
                    'screen5': '74',
                    'screen6': '7',
                    'screen7': '45',
                    'screen8': '2',
                    'screen9': 'Nada',
                    'screen10': '16',
                    'screen11': 'Uma linha',
                    'screen12': '35',
                    'screen13': '96',
                    'screen14': 'Duas linhas curvas',
                }
            }
        },
        methods: {
            parseDate(date) {
                if (!date || !this.moment(date).isValid()) return '-'
                return this.moment(date).format('DD [de] MMMM [de] YYYY [às] HH:mm')
            },
            hasError(key, value) {
                if (!value) return true
                return this.answers[key] !== value
            }
        }
    }
</script>
<style lang="scss" scoped>
    .title {
        margin-top: 2rem;
        color: var(--dark-blue, #0C1D59);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .subtitle {
        color: var(--type-active, #525C7A);
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        margin-top: 0.6rem;
    }
    .time {
        color: var(--blue-500, #305BF2);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
    }
    
    .screens-wrapper {
        width: 100%;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        white-space: nowrap;
        overflow-y: auto;
    }
    .screen {
        padding: 0.75rem;
        border: 1px solid #74acde;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: aliceblue;

        .screen-number {
            font-size: 0.6rem;
        }

        .response {
            font-size: 0.8rem;
            font-weight: 500;
        }
        .rigth-response {
            font-size: 0.6rem;
            color: green;
        }

        &.error {
            border: 1px solid #ea7a85ed;
            background-color: #f8d7da;
        }
    }
</style>